<template>
  <div id="app">
    <p class="medium-font"> Please contact {{ value }} </p>
  </div>
  
</template>

<style>
  @import './assets/styles/style.css';
</style>

<script>
export default {
  name: "App",
  data() {
    return {
      value: "us any time"
    };
  }
};
</script>
